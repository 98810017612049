.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 30px;
  background-color: #FFEDB6;
  overflow: hidden;
  padding: 0 20px; 
}

.nav a {
  text-decoration: none;
}

.nav-title {
  font-size: 24px;
  font-weight: 700;
  color: #192B3E;
  margin: 0;
}

.navLink {
  display: inline-block;
  color: #192B3E;
  background-color: #FFEDB6;
  text-align: center;
  padding: 24px 10px;
  text-decoration: none;
  font-size: 15px;
  border: none;
  cursor: pointer;
  outline: 0;
}

.logo {
  display: inline-block;
  color: #192B3E;
  background-color: #FFEDB6;
  text-align: center;
  padding: 24px 10px;
  text-decoration: none;
  font-size: 15px;
  border: none;
  cursor: pointer;
  outline: 0;
}

.collection {
  display: inline-block;
  color: #192B3E;
  background-color: #FFEDB6;
  text-align: center;
  padding: 24px 10px;
  text-decoration: none;
  font-size: 15px;
  border: none;
  cursor: pointer;
  outline: 0;
}

.home {
  display: inline-block;
  color: #192B3E;
  background-color: #FFEDB6;
  text-align: center;
  padding: 24px 10px;
  text-decoration: none;
  font-size: 15px;
  border: none;
  cursor: pointer;
  outline: 0;
}

.add-record {
  display: inline-block;
  color: #192B3E;
  background-color: #FFEDB6;
  text-align: center;
  padding: 24px 10px;
  text-decoration: none;
  font-size: 15px;
  border: none;
  cursor: pointer;
  outline: 0;
}

.logout {
  display: inline-block;
  color: #192B3E;
  background-color: #FFEDB6;
  text-align: center;
  padding: 24px 10px;
  text-decoration: none;
  font-size: 15px;
  border: none;
  cursor: pointer;
  outline: 0;
}

.about {
  display: inline-block;
  color: #192B3E;
  background-color: #FFEDB6;
  text-align: center;
  padding: 24px 10px;
  text-decoration: none;
  font-size: 15px;
  border: none;
  cursor: pointer;
  outline: 0;
}

.navLink:hover {
  background-color: #558E8F;
}

.logo-container {
  display: flex;
  align-items: center;
}

.Vinyl-Verse-Logo {
  width: 150px;
  height: 150px;
  
}
