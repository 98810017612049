html,
body,
#react-root {
  height: 100%;
  background-color: #fef8e1;
}

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0px;
}

img {
  display: inline-block;
  max-width: 100%;
}

/* --------------------------------------------------------------------------------
Container
-------------------------------------------------------------------------------- */

.container {
  padding: 20px;
}

/* --------------------------------------------------------------------------------
Button
-------------------------------------------------------------------------------- */

.btn {
  display: inline-block;
  padding: 10px 20px;
  border-color: #014041;
  border-width: 1px 1px 3px;
  border-radius: 4px;
  background-color: #192B3E;
  color: #f8f8f8;
  font-size: 1.1rem;
  outline: 0;
  cursor: pointer;
}

.btn_sizeMin {
  min-height: 50px;
  min-width: 100px;
  background-color: #192B3E;
}

.btn_sizeSm {
  padding: 10px 15px;
  font-size: 0.8rem;
}

.btn_sizeFull {
  display: block;
  width: 100%;
}

.btn:hover {
  background-color: #192B3E;
  text-decoration: none;
}

/* This line makes the "Register" and "Login" buttons look like links */
.btn_asLink {
  padding: 0;
  border-width: 0;
  border-bottom: 1px solid #444;
  border-radius: 0;
  background: none;
  font: inherit;
  color: #444;
}

.btn_asLink:hover {
  background-color: transparent;
  border-color: #008183;
  color: #008183;
}

/* --------------------------------------------------------------------------------
Alert
-------------------------------------------------------------------------------- */

.alert {
  box-sizing: border-box;
  padding: 15px 10px;
  margin: 15px 0;
  border: 2px solid #e01616;
  background-color: #efefef;
  border-radius: 3px;
  color: #e01616;
  font-size: 0.8rem;
}

/* --------------------------------------------------------------------------------
Form Treatment
-------------------------------------------------------------------------------- */

.formPanel {
  box-sizing: border-box;
  width: 400px;
  max-width: 100%;
  padding: 25px;
  margin: 0 auto 20px;
  background-color: #FFEDB6;
  border-radius: 3px;
  box-shadow: 0 1px 2px rgb(0, 0, 0, 0.4);
}

input {
  margin: 5px;
  margin-bottom: 10px;
}
